import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import "../../assets/styles/auth-styles.css";
import Cloud from "../../assets/icons/cloud-icon.png";
import UniversalButton from "../../ultilities/Buttons";

export default function SystemMessage() {
  const location = useLocation(); // Get location object
  const message = location.state?.message; // Access message from state
  const navigate = useNavigate(); // Get the navigation function

  const handleBackToSignIn = () => {
    navigate("/signin"); // Redirect to the sign-in path
  };
  return (
    <div className="background">
      <div className="container" id="container-cloud">
        <img src={Cloud} alt="Cloud background" className="cloud" />
        <p className="cloud-text inside-container">{message}</p>
      </div>
      <div className="button-container">
        <UniversalButton
          label="Back to Sign In"
          buttonStyle=""
          onClick={handleBackToSignIn}
        />
      </div>
    </div>
  );
}

import React, { useState, useContext } from "react";
import Box from "@mui/material/Box";

import AspectRatioCheck from "../AspectRatioCheck.js";
import { useNavigate, useLocation } from "react-router-dom";
import { globalContext } from "../../Context.js";

import EnEsButton from "./buttons/EnEsButton.js";
import BackpackButton from "./buttons/BackpackButton.js";
import SpeakingButton from "./buttons/SpeakingButton.js";

let buttonStyleSound = {
  position: "absolute",
  cursor: "pointer",
  width: "3.8vw",
  height: "auto",
  right: "8vw",
  top: "2.5vh",
};

let buttonStyleMenu = {
  position: "absolute",
  cursor: "pointer",
  width: "5vw",
  height: "auto",
  right: "2vw",
  top: "1.5vh",
};


const greenRectangleStyle = {
  position: "absolute",
  // width: '20vw',
  minWidth: "20vw",
  width: "auto",
  height: "6vh",
  backgroundColor: "#c2d2a3",
  left: "2vw",
  top: "1.8vh",
  border: ".5vw solid black",
  borderRadius: "1vw",

  paddingLeft: "1vw",
  paddingRight: "1vw",
};

let greenRectangleTextStyle = {
  position: "relative",
  color: "black",
  fontFamily: "Fredoka, sans-serif",
  fontSize: "3vw",
  fontWeight: 600,
};

const HelperButtons = () => {
  const parentStyle = {
    display: "block",
    right: "1vw",
    width: "28vw",
    top: "1.5vh",
    position: "absolute",
  }

  const flexLayoutStyle = {
    display: "flex",
    alignItems: "stretch",
    height: "10vh",
  }

  return (
    <Box sx={parentStyle}>
      <Box sx={flexLayoutStyle}>
        <Box sx={{ flexGrow: 1 }}>
          <EnEsButton />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <SpeakingButton />
        </Box>
        <Box sx={{ flexGrow: 1 }}>
          <BackpackButton />
        </Box>
      </Box>
    </Box>
  );
};

const MainHeader = ({ showButtons, topicText, special }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedOption, setSelectedOption] = useState("");
  const { slideId, setSlideId, state, setState, lessonId, setLessonId } =
    useContext(globalContext);

  const handleDropdownChange = (event) => {
    setSelectedOption(event.target.value);
    // Add language setting code here later
  };

  const handleSoundButtonClick = () => {
    console.log("Sound button clicked");
  };

  const handleMenuButtonClick = () => {
    console.log("in header: " + lessonId);
    navigate("../tocPage", { state: { lessonId: lessonId } });
  };

  if (AspectRatioCheck()) {
    // Screen is too wide
    // console.log("Screen is wider than target aspect ratio");
    greenRectangleTextStyle = {
      ...greenRectangleTextStyle,
      fontSize: "2vw",
      top: "5%",
    };

    buttonStyleSound = {
      ...buttonStyleSound,
      width: "3vw",
      top: "3vh",
    };

    buttonStyleMenu = {
      ...buttonStyleMenu,
      width: "4vw",
      top: "2vh",
    };
  }
  //if (!render) return null;
  return (
    <Box>
      <Box style={greenRectangleStyle}>
        <Box style={greenRectangleTextStyle}> 
          {topicText} 
        </Box>
      </Box>

      {showButtons && <HelperButtons />}
    </Box>
  );
};

export default MainHeader;

import React, { useState, useContext } from "react";
import AspectRatioCheck from "../AspectRatioCheck.js";
import { useNavigate } from "react-router-dom";
import { globalContext } from "../../Context.js";
import LeftArrow from "../../assets/buttons/Left_Arrow.png";

let backArrowButtonStyle = {
  position: "absolute",
  cursor: "pointer",
  width: AspectRatioCheck() ? "6vw" : "7vw",
  height: "auto",
  left: "8vw",
  top: "12vh",
  filter: "brightness(90%) saturate(3)",
};

const BackArrowButton = ({ render, previousID, previousPageName }) => {
  const { setSlideId, state } = useContext(globalContext);
  const [isBackArrowHovered, setBackArrowHovered] = useState(false);
  const navigate = useNavigate();

  const handleBackArrowHover = () => {
    backArrowButtonStyle = {
      ...backArrowButtonStyle,
      filter: "brightness(100%)",
    };
    setBackArrowHovered(true);
  };
  const handleBackArrowLeave = () => {
    backArrowButtonStyle = {
      ...backArrowButtonStyle,
      filter: "brightness(90%) saturate(3)",
    };
    setBackArrowHovered(false);
  };

  const handleBackArrowClick = () => {
    // console.log('Navigating to', leftLink);
    // navigate(leftLink);
    // TODO
    setSlideId(state.prevSlide);
  };

  // TODO to where???
  const handleExitLessonClick = () => {
    navigate("../" + previousPageName, { state: { unitId: previousID } });
  };

  return (
    <div>
      <img
        src={LeftArrow}
        alt="Back Arrow"
        style={backArrowButtonStyle}
        onClick={handleExitLessonClick}
        onMouseEnter={handleBackArrowHover}
        onMouseLeave={handleBackArrowLeave}
      />
    </div>
  );
};

export default BackArrowButton;

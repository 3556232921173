import React, { useState, useEffect } from "react";
import "../../assets/styles/selection-styles.css";

export function PictureInBtnExample({ question, options }) {
  const [selectedOption, setSelectedOption] = useState(-1);
  useEffect(() => {
    setSelectedOption(0);
  }, [question, options]);

  return (
    <div>
      <div className="horizontal-mc-container">
        <div className="question-rectangle">
          <div className="question-text">{question}</div>
        </div>
        <div className="picture-btn-container">
          {/* setSelectorReturnValue determines if the answer is correct or incorrect */}
          {options !== undefined ? (
            options.map((option, index) => (
              <button
                className={`picture-btn display-btn ${
                  selectedOption === index ? "correct-btn" : ""
                }`}
                
              >
                <div className="picture-container">
                  <img
                    src={process.env.REACT_APP_BACKEND_HOST + option.media[0]}
                    alt=""
                  />
                </div>
                {option.text}
              </button>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
}

export function LeftRightExample({ question, options }) {
  const [selectedOption, setSelectedOption] = useState(-1);
  useEffect(() => {
    setSelectedOption(0);
  }, [question, options]);
  return (
    <div>
      <div className="higher-question-rectangle">
        <div className="question-text">{question}</div>
      </div>
      <div className="left-right-container">
        {options !== undefined ? (
          options.map((option, index) => (
            <button
              className={`left-right-btn display-btn ${
                selectedOption === index ? "correct-btn" : ""
              }`}
            >
              {option.text}
            </button>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

// a display that has 2 buttons on both sides of the sprite
export function LeftRightDisplay({ options }) {
  return (
    <div>
      <div className="left-right-display-container">
        {options !== undefined ? (
          options.map((option) => (
            <button className={`left-right-btn display-btn`}>
              {option.text}
            </button>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

import React from "react";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabList from "@mui/lab/TabList";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";

import ProfileImage from "../../assets/characters/C_Volcanologist_Blue_Temperature_Temp.png";
import BadgeImage from "../../assets/badges/UI_Sound_Wizard_Badge_Diamond.png";
import bgImage from "../../assets/backgrounds/BG_Default.png";
import profileSwitchIcon from "../../assets/icons/UI_Profile_Switch.png";
import badgeProfileIcon from "../../assets/icons/UI_Badge_Profile_icon.png";
import PreviousArrowButton from "../../components/backpacks/BackArrowButton";
import GreenTitleBar from "../../components/backpacks/GreenTitleBar";
import highlightedImage from "../../components/backpacks/HighlightImage";
import highlightedBox from "../../components/backpacks/HighlightBox";

const parentBox = {
  display: "flex",
  margin: "4vh 10vw 0vh 8vw",
};

const profileBox = {
  border: ".5vw solid black",
  borderRadius: "1vw",
  width: "35%",
  position: "relative",
  overflow: "hidden",
};

const badgeBox = {
  width: "60%",
  height: "auto",
  borderRadius: "1vw",
  margin: "0vh 2vw 0vh 2vw",
};

const blueRectangleStyle = {
  margin: "1vw 2vw",
  backgroundColor: "#a7e4e1",
  border: ".5vw solid black",
  borderRadius: "1vw",

  color: "black",
  fontFamily: "Fredoka, sans-serif",
  fontWeight: 600,
  textAlign: "center",
  fontSize: "4vw",
};

const tabStyles = {
  color: "black",
  fontFamily: "Fredoka, sans-serif",
  fontWeight: 500,
  textAlign: "center",
  fontSize: "2.5vh",
  border: ".5vw solid black",
  borderRadius: "0vw 0vw 1vw 1vw",
  background: "white",
};

const tabBodyStyle = {
  background: "white",
  border: ".5vw solid black",
  borderRadius: "1vw 1vw 0vw 0vw",
  borderBottom: "0",
};

const badgeContainersStyle = {
  justifyContent: "center",
  display: "flex",
  flexWrap: "wrap",
  overflow: "hidden",
};

const starBadgeStyle = {
  padding: "1vh",
  height: "45%",
  width: "30%",
  objectFit: "contain",
};

const moduleBadgeStyle = {
  padding: "1vh",
  height: "29%",
  width: "21%",
  objectFit: "contain",
};

const unitBadgeStyle = {
  padding: "1vh",
  height: "45%",
  width: "40%",
  objectFit: "contain",
  margin: "auto",
};

const otherBadgeStyle = {
  padding: "1vh",
  height: "85%",
  width: "40%",
  objectFit: "contain",
  margin: "auto",
};

const BadgesContainers = ({ int, style, showTutorial }) => {
  // TODO have an array of actual badge images here
  return (
    <Box
      sx={{ ...badgeContainersStyle, height: showTutorial ? "52vh" : "40vh" }}
    >
      {Array.from({ length: int }, (_, index) => (
        <img key={index} src={BadgeImage} style={style} />
      ))}
    </Box>
  );
};

const BadgeAndProfile = ({
  showTutorial,
  profileHighlighted,
  characterSwitchHighlighted,
  badgeBoxHighlighted,
  firstBadgeHighlighted,
  tabHighlighted,
}) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Box>
      <GreenTitleBar
        icon={badgeProfileIcon}
        title={"Badges and Profile"}
        boxWidth={"35%"}
        topMargin={(showTutorial? "8vh": null)}/>
      <Box
        sx={parentBox}>
        {!showTutorial && (
          <PreviousArrowButton // TODO not linked correctly
          />
        )}
        <Box sx={{ ...badgeBox, ...(badgeBoxHighlighted && highlightedBox) }}>
          <TabContext value={value}>
            <TabPanel value="1" sx={tabBodyStyle}>
              <BadgesContainers
                int={5}
                style={starBadgeStyle}
                showTutorial={!showTutorial}
              />
            </TabPanel>

            <TabPanel value="2" sx={tabBodyStyle}>
              <BadgesContainers
                int={11}
                style={moduleBadgeStyle}
                showTutorial={!showTutorial}
              />
            </TabPanel>

            <TabPanel value="3" sx={tabBodyStyle}>
              <BadgesContainers
                int={4}
                style={unitBadgeStyle}
                showTutorial={!showTutorial}
              />
            </TabPanel>

            <TabPanel value="4" sx={tabBodyStyle}>
              <BadgesContainers
                int={2}
                style={otherBadgeStyle}
                showTutorial={!showTutorial}
              />
            </TabPanel>

            <TabList
              variant="fullWidth"
              onChange={handleChange}
              aria-label="lab API tabs example"
              TabIndicatorProps={{
                style: { display: "none" },
              }}
              sx={{
                "&& .Mui-selected": {
                  border: ".5vw solid black",
                  borderRadius: "0vw 0vw 1vw 1vw",
                  borderTop: "0",
                  color: "black",
                },
              }}
            >
              <Tab label="Stars" value="1" disableRipple sx={tabStyles} />
              <Tab
                label="Modules"
                value="2"
                disableRipple
                sx={{
                  ...tabStyles,
                  background: tabHighlighted ? "#FFC702" : "white",
                }}
              />
              <Tab label="Units" value="3" disableRipple sx={tabStyles} />
              <Tab label="Others" value="4" disableRipple sx={tabStyles} />
            </TabList>
          </TabContext>
        </Box>

        <Box sx={{ ...profileBox, ...(profileHighlighted && highlightedBox) }}>
          <img
            src={bgImage}
            style={{
              // TODO temp image need to change
              width: "100%",
              height: "100%",
              objectFit: "cover",
              position: "absolute",
            }}
          ></img>
          <img
            src={profileSwitchIcon}
            style={{
              right: "5%",
              bottom: "3%",
              position: "absolute",
              ...(characterSwitchHighlighted && highlightedImage),
            }}
          ></img>
          <Box
            sx={{
              position: "absolute",
              width: "100%",
              overflow: "hidden",
            }}
          >
            <Box sx={{ position: "relative" }}>
              <Box sx={blueRectangleStyle}>Name</Box>
              <img
                src={ProfileImage}
                style={{
                  // TODO temp image need to change
                  objectFit: "contain",
                  width: "100%",
                }}
              ></img>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BadgeAndProfile;

const targetAspectRatio = 1.81;
const screenWidth = window.innerWidth;
const screenHeight = window.innerHeight;
const aspectRatio = screenWidth / screenHeight;
const roundedAspectRatio = Math.round((aspectRatio + Number.EPSILON) * 100) / 100;

const booleanFunction = () => {
    // console.log("roundedAspectRatio: ", roundedAspectRatio);
    if(roundedAspectRatio > targetAspectRatio){
        // console.log("rounded aspect ratio is greater than target aspect ratio");
    }
    return roundedAspectRatio > targetAspectRatio;
};

export default booleanFunction;
import React from "react";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PreviousArrowButton from "../../components/backpacks/BackArrowButton";
import GreenTitleBar from "../../components/backpacks/GreenTitleBar";

import backpackIcon from "../../assets/icons/UI_Backpack.png"
import tutorialIcon from "../../assets/icons/UI_tutorial_icon.png"
import profileBadgeIcon from "../../assets/icons/UI_Badge_Profile_icon.png"
import tocIcon from "../../assets/icons/UI_TOC_Icon.png"
import moduleIcon from "../../assets/icons/UI_Modules_Icon.png"
import settingIcon from "../../assets/icons/UI_Settings_Icon.png"
import highlightedImage from "../../components/backpacks/HighlightImage";
import highlightedBox from "../../components/backpacks/HighlightBox";
import TitleText from "../../components/backpacks/TitleText";

const scrollableLessonsStyle = {
  backgroundColor: "white",
  border: ".7vw solid black",
  borderRadius: ".5vw",
  overflowY: "hidden",
  overflowX: "scroll",
  scrollbarColor: "#C2D2A3 transparent",
  whiteSpace: "nowrap",
  display: "flex",
  margin: "4vh 10vw 2vh"
};

const iconBoxStyle = {
  display: "flex",
  flexDirection: "column", 
  flex: 1,
  fontFamily: "Fredoka, sans-serif",
  fontSize: "1.7rem",
  fontWeight: 600,
  textAlign: "center",
  whiteSpace: "pre-wrap",
}

const signOutStyle = {
  backgroundColor: "#A7E4E1",
  border: ".7vw solid black",
  borderRadius: "1.5vw",

  fontFamily: "Fredoka, sans-serif",
  fontSize: "2.5rem",
  fontWeight: 500,
  textAlign: "center",
  margin: "auto", 
  padding: ".5rem",

  width: "17vw",
  display: "flex",
  flexDirection: "column", 
}


export default function Backpack({
  showTutorial,
  tutorialHighlighted, 
  badgeProfileHighlighted, 
  tocHighlighted, 
  modulesHighlighted, 
  settingsHighlighted, 
  signOutHighlighted
}) {

const iconData = [
  {
    img: tutorialIcon, 
    title: "Tutorial",
    highlighted: tutorialHighlighted
  }, 
  {
    img: profileBadgeIcon, 
    title: "Badges and\nProfile",
    highlighted: badgeProfileHighlighted
  }, 
  {
    img: tocIcon, 
    title: "Table of\nContents",
    highlighted: tocHighlighted
  }, 
  {
    img: moduleIcon, 
    title: "Modules",
    highlighted: modulesHighlighted
  }, 
  {
    img: settingIcon, 
    title: "Settings",
    highlighted: settingsHighlighted
  }, 
]
  
  // const location = useLocation();
  // const [unitId, setUnitId] = useState(
  //   location.state === null ? "6663546d8060991a53417aa3" : location.state.unitId
  // );
  // const [state, setState] = useState({});
  // const navigate = useNavigate();
  // useEffect(() => {
  //   fetch(process.env.REACT_APP_BACKEND_HOST + "api/getUnit/" + unitId)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log(data);
  //       setState(data);
  //     });
  // }, [unitId]);

  return (
    <Box>
      {!showTutorial &&
      <PreviousArrowButton showLeftButton={true} /> } 
      <GreenTitleBar
        icon={backpackIcon}
        title={"Backpack"}
        boxWidth={"27%"}
        topMargin={(showTutorial? "8vh": null)}/>
      
      <Box sx={{...scrollableLessonsStyle, height: showTutorial? "40vh" : "50vh" }}>
        {iconData.map((items) => (
            <Box sx={{...iconBoxStyle, margin: showTutorial? "8vh 0% 0% 0%" : "14vh 0% 0% 0%" }}>
              <img 
                src={items.img}
                style={{
                  height:"40%", 
                  objectFit: "contain",
                  ...(items.highlighted && highlightedImage)
                }} >
              </img>
              <Box sx={{margin: "1vh 0vw"}}> 
              {items.highlighted?  
              <TitleText 
                text={items.title} 
                forBackpackLabels={true} /> 
                : items.title
              }
              </Box>
            </Box>
            ))}
      </Box>
      <Box sx={{...signOutStyle, ...( signOutHighlighted && highlightedBox )}}>
        Sign Out
      </Box>
    </Box>
  );
}

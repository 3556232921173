import React, { useEffect } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import FakeHome from "./pages/authentication/FakeHome";
import PrivateRoute from "./pages/authentication/PrivateRoute.js";
import SignIn from "./pages/authentication/SignIn.js";
import SignUp from "./pages/authentication/SignUp.js";
import SystemMessage from "./pages/authentication/SystemMessage.js";
import Converter from "./dev/Converter.js";
import { UserProvider } from "./ultilities/user.context.js";

import "./App.css";

// Refactor
import ContentPage from "./pages/ContentPage.js";
import LessonPage from "./pages/LessonPage.js";
import TOCPage from "./pages/TOCPage.js";

//import LandingPage from './pages/LandingPage.js';
import Backpack from "./pages/hub/Backpack.js";
import Setting from "./pages/hub/Setting.js";
import ModulePage from "./pages/ModulePage.js";
import UnitPage from "./pages/UnitPage.js";
import BadgeAndProfile from "./pages/hub/BadgeAndProfile.js";
import LandingPage from "./pages/LandingPage.js";
import TutorialsPage from "./pages/hub/TutorialPage.js";
import CharacterSelect from "./pages/hub/CharacterSelect.js";

import Editor from "./dev/Editor.js";
import Preview from "./dev/Preview.js";

function App() {
  useEffect(() => {
    // document.body.classList.add("loading-page");
    // TODO: figure out what this does
    return function cleanup() {
      document.body.classList.remove("landing");
    };
  });

  return (
    <Router>
      <UserProvider>
        <Routes>
          <Route path="/signin" element={<SignIn />} />
          <Route path="/signup" element={<SignUp />} />
          <Route path="/system-message" element={<SystemMessage />} />
          <Route exact path="/home" element={<FakeHome />} />
          <Route element={<PrivateRoute />} />
        </Routes>
      </UserProvider>
      <Routes>
        <Route path="/setting" element={<Setting />} />
        <Route path="/backpack" element={<Backpack />} />
        <Route path="/contentPage" element={<ContentPage />} />
        <Route path="/lessonPage" element={<LessonPage />} />
        <Route path="/tocPage" element={<TOCPage />} />
        <Route path="/badgeAndProfile" element={<BadgeAndProfile />} />
        <Route path="/characterSelect" element={<CharacterSelect />} />
        <Route path="/landingPage" element={<LandingPage />} />
        <Route path="/tutorialPage" element={<TutorialsPage />} />
        <Route path="/modulePage" element={<ModulePage />} />
        <Route path="/unitPage" element={<UnitPage />} />
        <Route path="/editor" element={<Editor />} />
        <Route path="/preview" element={<Preview />} />
        <Route path="/converter" element={<Converter />} />
      </Routes>
    </Router>
  );
}

export default App;

// testing

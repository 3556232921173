import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { User, UserContext, UserProvider } from "../../ultilities/user.context";

import IconEmail from "../../assets/icons/email-icon.png";
import IconPassword from "../../assets/icons/password-lock-icon.png";
import IconName from "../../assets/icons/profile-icon.png";
import IconSchool from "../../assets/icons/school-icon.png";
import CreateAccountTitle from "../../assets/titles/title-create-account.png";
import "../../assets/styles/auth-styles.css";
import UniversalButton from "../../ultilities/Buttons";

const InputField = ({ label, type, iconSrc, value, onChange }) => {
  return (
    <div className="input-container">
      {iconSrc && <img src={iconSrc} alt={`${label} icon`} className="icon" />}
      <input
        placeholder={label}
        type={type}
        value={value}
        onChange={onChange}
        className="input"
      />
    </div>
  );
};

export default function SignUp() {
  let isMounted = true;
  const navigate = useNavigate();
  const location = useLocation();

  const { emailPasswordSignup } = useContext(UserContext);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [schoolInfo, setSchoolInfo] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const handleFirstNameChange = (event) => setFirstName(event.target.value);
  const handleLastNameChange = (event) => setLastName(event.target.value);
  const handleSchoolInfoChange = (event) => setSchoolInfo(event.target.value);
  const handleEmailChange = (event) => setEmail(event.target.value);
  const handlePasswordChange = (event) => setPassword(event.target.value);
  const handleConfirmPasswordChange = (event) =>
    setConfirmPassword(event.target.value);

  const inputFields = [
    {
      label: "First Name",
      type: "text",
      iconSrc: IconName,
      value: firstName,
      onChange: handleFirstNameChange,
    },
    {
      label: "Last Name",
      type: "text",
      iconSrc: IconName,
      value: lastName,
      onChange: handleLastNameChange,
    },
    {
      label: "School Name",
      type: "text",
      iconSrc: IconSchool,
      value: schoolInfo,
      onChange: handleSchoolInfoChange,
    },
    {
      label: "Student ID",
      type: "text",
      iconSrc: IconSchool,
      value: schoolInfo,
      onChange: handleSchoolInfoChange,
    },
    {
      label: "Password",
      type: "password",
      iconSrc: IconPassword,
      value: password,
      onChange: handlePasswordChange,
    },
    {
      label: "Confirm Password",
      type: "password",
      iconSrc: IconPassword,
      value: confirmPassword,
      onChange: handleConfirmPasswordChange,
    },
    {
      label: "Email",
      type: "email",
      iconSrc: IconEmail,
      value: email,
      onChange: handleEmailChange,
    },
  ];

  const signUpPressed = async (event) => {
    if (email === "" || password === "" || confirmPassword === "") {
      alert("Make sure to fill in all the input fields!");
    } else if (password !== confirmPassword) {
      alert("Password and confirm password don't match");
    } else {
      event.preventDefault();
      try {
        // register the email to mongo authentication
        const newUser = await emailPasswordSignup(email, password);
        // added user info to our db
        await fetch(process.env.REACT_APP_BACKEND_HOST + "/api/register", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            userId: newUser.id,
            firstName: firstName,
            lastName: lastName,
            school: schoolInfo,
            email: email,
            progress: 0,
            onboarding: true,
          }),
        });

        // redirect to email verification
        redirectNow();
      } catch (error) {
        if (error.statusCode === 409) {
          navigate("/system-message", {
            state: {
              message: "Oh no! An account with that email already exists!",
            },
          });
        } else {
          alert(error);
        }
      }
      console.log("Sign up pressed with email:", email);
    }
  };

  const redirectNow = () => {
    const redirectTo = location.search.replace("?redirectTo=", "");

    if (isMounted) {
      navigate(redirectTo ? redirectTo : "/system-message", {
        state: { message: "Check your email and verify your account!" },
      });
    }
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="background">
      <div className="container">
        <img
          src={CreateAccountTitle}
          alt="Create your account"
          className="mitos-title"
        />
      </div>
      <form onSubmit={signUpPressed}>
        {/* input fields */}
        <div className="col-container">
          {inputFields.map((field) => (
            <InputField key={field.label} {...field} />
          ))}
        </div>

        {/* sign up button */}
        <div className="section-container">
          <div className="button-container">
            <UniversalButton label="Sign Up" buttonStyle="" />
          </div>
        </div>
      </form>
    </div>
  );
}

import React from "react";
import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import BackArrowButton from "../../components/backpacks/BackArrowButton";
import ContentListing from "../../components/backpacks/ContentListing";
import TitleText from "../../components/backpacks/TitleText";
import ProfileImage from "../../assets/characters/C_Volcanologist_Blue_Temperature_Temp.png";
import GreenTitleBar from "../../components/backpacks/GreenTitleBar";
import profileSwitchIcon from "../../assets/icons/UI_Profile_Switch.png";


const scrollableLessonsStyle = {
  height: "60vh",
  width: "80vw",
  backgroundColor: "white",
  border: ".7vw solid black",
  borderRadius: ".5vw",
  overflowY: "hidden", // hidden
  overflowX: "scroll",
  scrollbarColor: "#C2D2A3 transparent",
  whiteSpace: "nowrap",
  display: "flex",
  margin: "18vh auto",
};

const iconBoxStyle = {
  display: "flex",
  flexDirection: "column", 
  flex: 1,
  fontFamily: "Fredoka, sans-serif",
  fontSize: "5vh",
  fontWeight: 600,
  textAlign: "center",
  whiteSpace: "pre-wrap",
  margin: "auto"
  }

const tutorialStyle = {
  height: '47vh',
  margin: "15vh auto",
}


const characterData = [
  {
    img: ProfileImage,
    title: "Blue",
  }, 
  {
    img: ProfileImage, 
    title: "Pink",
  }, 
  {
    img: ProfileImage, 
    title: "Green",
  }, 
  {
    img: ProfileImage, 
    title: "Blue",
  }, 
  {
    img: ProfileImage,
    title: "Settings",
  }, 
  ]

export default function CharacterSelect({ showTutorial }) {
  const location = useLocation();
  const [unitId, setUnitId] = useState(
    location.state === null ? "6663546d8060991a53417aa3" : location.state.unitId
  );
  const [state, setState] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getUnit/" + unitId)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setState(data);
      });
  }, [unitId]);

  return (
    <Box>
      {!showTutorial && (
        <BackArrowButton // TODO not linked correctly
          previousPageName={"landingPage"}
        />)}
      <TitleText
        text={"Characters"}/>

      <Box sx={{...scrollableLessonsStyle, ...(showTutorial && tutorialStyle)}}>
          {characterData.map((items) => (
              <Box sx={{display: "flex", border: "3px solid red"}}>
                  <Box sx={iconBoxStyle}> 
                      <img 
                          src={items.img}
                          style={{
                          height:"35vh", 
                          margin: "auto",
                          objectFit: "contain",
                          }} >
                      </img>
                      <Box> 
                          {items.title} 
                      </Box>
                  </Box>
              </Box> ))}
      </Box>
    </Box>
  );
}

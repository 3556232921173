import React from "react";
import { Box } from "@mui/material";
import Slider from "@mui/joy/Slider";
import { useState } from "react";

import character1 from "../assets/characters/C_Volcanologist_Blue_Wave.png";
import character2 from "../assets/characters/C_Volcanologist_Blue_Temperature_Temp.png";
import character3 from "../assets/characters/C_Volcanologist_Blue_Temperature_03.png";

import thermometer1 from "../assets/objects/P_Thermometer.png";
import thermometer2 from "../assets/objects/P_Thermometer_Half_Up.gif";
import thermometer3 from "../assets/objects/P_Thermometer_Up.gif";

import rope from "../assets/objects/Rope_Left.png";

const sliderTextStyle = {
  display: "flex",
  color: "black",
  fontFamily: "Fredoka, sans-serif",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "2.4vw",
  position: "absolute",
};

// need height width of the silder

const silderData = {
  width: 636,
  left: 338,
  top: 130,
};

const itemAndPosData = [
  {
    images: [character1, character2, character2, character3],
    width: 417,
    top: 194,
    left: [16, 200, 400, 675],
  },
  {
    images: [thermometer1, thermometer2, thermometer2, thermometer3],
    width: 288,
    top: 104,
    left: [-65, -65, -65, -65],
  },
];

const silderData2 = {
  width: 350,
  left: 559,
  top: 402,
};

const itemAndPosData2 = [
  {
    images: rope,
    width: 993,
    top: 194,
    left: [-361, -200, -100, 0],
  },
];

export default function ImageSlider({ changingImages, sliderStyle, audioSources, slideId }) {
  const [value, setValue, setAudioSource] = useState(0);

  function handleChange(e) {
    setValue(e.target.value);
  }


  return (
    <Box>
      {changingImages.map((items) => (
        <Box>
          <Box>
            <img
              src={process.env.REACT_APP_BACKEND_HOST + items.media[value]}
              style={{
                objectFit: "contain",
                width: (items.width / 832) * 100 + "vh",
                left: (items.left[value] / 1280) * 100 + "vw",
                top: (items.top / 832) * 100 + "vh",
                position: "absolute",
              }}
              alt=""
            ></img>
          </Box>
        </Box>
      ))}
      <Box
        sx={{
          ...sliderTextStyle,
          width: (sliderStyle.width / 1280) * 100 + "vw",
          left: (sliderStyle.left / 1280) * 100 + "vh",
          top: (sliderStyle.top / 1280) * 100 + "vw",
        }}
      >
        <Slider
          defaultValue={0}
          min={0}
          max={3}
          step={1}
          disableRipple
          valueLabelDisplay="off"
          variant="soft"
          onChange={handleChange}
          //value={value}
          sx={{
            "--Slider-trackSize": "2vh",
            "--Slider-thumbSize": "6vh",

            "& .MuiSlider-thumb": {
              background: "#FFFFFF",
              border: ".3vw solid black",
            },
            "& .MuiSlider-track": {
              background: "#FFEB34",
              border: ".3vw solid black",
            },
            "& .MuiSlider-rail": {
              background: "#EC4511",
              border: ".3vw solid black",
            },
          }}
        />
      </Box>
    </Box>
  );
}

import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import ContentListing from "../components/backpacks/ContentListing";
import AspectRatioCheck from "../components/AspectRatioCheck";
import PreviousArrowButton from "../components/backpacks/BackArrowButton";
import TitleText from "../components/backpacks/TitleText";

const scrollableLessonsStyle = {
  height: "60vh",
  width: "80vw",
  backgroundColor: "white",
  border: ".7vw solid black",
  borderRadius: ".5vw",
  overflowY: "hidden", // hidden
  overflowX: "scroll",
  scrollbarColor: "#C2D2A3 transparent",
  whiteSpace: "nowrap",
  display: "flex", 
  margin: "17vh auto"
};

const forTutoriaStyle = {
  height: "50vh",
  margin: "16vh auto"
}

export default function UnitPage({showTutorial}) {
  const location = useLocation();
  const [unitId, setUnitId] = useState(
    location.state === null ? "6663546d8060991a53417aa3" : location.state.unitId === undefined ? "6663546d8060991a53417aa3" : location.state.unitId
  );
  const [state, setState] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getUnit/" + unitId)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setState(data);
      });
  }, [unitId]);

  return (
    <div>
      {!showTutorial &&
      <PreviousArrowButton // TODO not linked correctly
        previousPageName={"modulePage"}
      />}
      <TitleText text={state.name} />

      <div style={{...scrollableLessonsStyle, ...(showTutorial && forTutoriaStyle)}}>
        {state.lessons !== undefined ? (
          state.lessons.map((lesson) => (
            <ContentListing
              completed={false} // Completed is whether the player has finished the lesson, its not implemented yet
              available={true}
              nameOfTopic={"Lesson " + lesson.num + ":\n" + lesson.name}
              lessonThumbnail={lesson.thumbnail}
              scrollingCheck={true}
              modulesCheck={false}
              handleButtonClick={() => {
                navigate("../lessonPage", { state: { lessonId: lesson._id } });
              }}
            />
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

import React, { useState, useEffect, useContext } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../ultilities/user.context";

import "../../assets/styles/auth-styles.css";
import UniversalButton from "../../ultilities/Buttons";
import MitosTitle from "../../assets/titles/title-mitos.png";
import IconStudent from "../../assets/icons/student-book-icon.png";
import IconPassword from "../../assets/icons/password-lock-icon.png";
import SystemMessage from "./SystemMessage";

export default function SignIn() {
  let isMounted = true;
  const navigate = useNavigate();
  const location = useLocation();

  const { emailPasswordLogin } = useContext(UserContext);
  const [email, setEmail] = useState(""); // state for student ID
  const [password, setPassword] = useState(""); // state for password

  const signInPressed = async (event) => {
    if (email === "" || password === "") {
      alert("Make sure to fill in your email and password!");
    } else {
      event.preventDefault();
      try {
        const currentUser = await emailPasswordLogin(email, password);
        if (currentUser) {
          console.log(currentUser.id);
          console.log("Signing in with email...", email);
          redirectNow();
        }
      } catch (error) {
        // email or password is incorrect
        if (error.statusCode === 401) {
          navigate("/system-message", {
            state: { message: "Invalid email or password" },
          });
        } else {
          alert(error);
        }
      }
    }
  };

  const handleStudentIdChange = (event) => {
    setEmail(event.target.value);
  };
  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  // redirect when the successful logged in
  const redirectNow = () => {
    const redirectTo = location.search.replace("?redirectTo=", "");
    if (isMounted) {
      // navigate(redirectTo ? redirectTo : "/system-message", {
      //   state: { message: "SUCCESSFULLY SIGNED IN. SHOULD LINK TO HOME PAGE" },
      // });

      navigate("/home")
    }
  };

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <div className="background">
      <div className="container">
        <img src={MitosTitle} alt="MITOS Title" className="mitos-title" />
      </div>
      <form onSubmit={signInPressed}>
        <div className="section-container">
          {/* student id input */}
          <div className="input-container">
            {/* student id icon */}
            <img src={IconStudent} alt="Student ID icon" className="icon" />
            <input
              placeholder="Email"
              type="email"
              value={email}
              onChange={handleStudentIdChange}
              className="input"
            />
          </div>

          {/* password input */}
          <div className="input-container">
            {/* password icon */}
            <img src={IconPassword} alt="Password icon" className="icon" />
            <input
              placeholder="Password"
              type="password"
              value={password}
              onChange={handlePasswordChange}
              className="input"
            />
          </div>
        </div>

        {/* forgot password */}
        <div className="section-container forgot-password">
          <Link
            to="/system-message"
            className="no-underline"
            state={{ message: "Check your email to reset your password!" }}
          >
            Forgot password?
          </Link>
        </div>

        {/* sign in button */}
        <div className="section-container">
          <div className="button-container">
            <UniversalButton label="Sign In" buttonStyle="" />
          </div>
        </div>

        {/* don't have account */}
        <div className="section-container no-account">
          <span>Don't have an account? </span>
          <Link to="/signup" className="no-underline">
            Sign up
          </Link>
        </div>
      </form>
    </div>
  );
}

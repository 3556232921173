import React from "react";
import { Box } from "@mui/material";

const labelBackpackStyle = {
    fontFamily: "Fredoka, sans-serif",
    fontSize: "1.7rem",
    fontWeight: 600,
    textAlign: "center",
    position: "absolute", 
    width: "100%",
  }

const titleTextStyle = {
    fontFamily: "Fredoka, sans-serif",
    fontSize: "12vh",
    fontWeight: 600,
    textAlign: "center",
    position: "absolute", 
    width: "100%",
  }

const TitleText = ({text, forBackpackLabels}) => {
    // if (backpackLabels == true) {
    //     let textSize = "13vh"
    // }
    return (
        <Box>
        {forBackpackLabels? 
            <Box sx={{display: "flex", margin: "1vh 0vw"}} >
                <Box sx={{  position: "relative", width:"100%"}}>
                    <Box sx={{...labelBackpackStyle,
                        color: "#FFC702", "-webkit-text-stroke": "0.7vh #FFC702"}}>
                        {text}
                    </Box>
                    <Box sx={{...labelBackpackStyle,
                        color: "black"}}>
                        {text}
                    </Box>
                </Box>
            </Box>  
            :
            <Box sx={{display: "flex", margin: "10vh 10vw"}} >
            <Box sx={{  position: "relative", width:"100%"}}>
                <Box sx={{...titleTextStyle,
                    color: "white", "-webkit-text-stroke": "1.7vw black"}}>
                    {text}
                </Box>
                <Box sx={{...titleTextStyle,
                    color: "#C2D2A3"}}>
                    {text}
                </Box>
            </Box>
        </Box>
        }
        </Box>
    )
}

export default TitleText;
import React from 'react';
import "../assets/styles/auth-styles.css"

const UniversalButton = ({
  label = 'Submit', // default label
  type = 'submit', // 'submit' as the default type
  buttonStyle = 'button', // Default button style class
  disabled = false, 
  ...otherProps // capture additional props for flexibility
}) => {
  return (
    <button
      type={type}
      className={`button ${buttonStyle}`} // combine default and custom styles
      disabled={disabled}
      {...otherProps} // spread remaining props
    >
      {label}
    </button>
  );
};

export default UniversalButton;

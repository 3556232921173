import React from 'react';
import backpackIcon from '../../../assets/icons/UI_Backpack.png';
import highlightedImage from '../../backpacks/HighlightImage';

export default function BackpackButton({highlighted}) {
    //const navigate = useNavigate();
    return (
        <img 
            src={backpackIcon} 
            style={{height:"100%", ...(highlighted && highlightedImage)}}> 
        </img>
    );
}
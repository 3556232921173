import React, { useState, useEffect } from "react";
import { CorrectPopUp, IncorrectPopUp } from "../display/PopUp";
import "../../assets/styles/selection-styles.css";

export default function MCLeftRight({
  question,
  options,
  message,
  setSelectorReturnValue,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);

  // handle pop-up and correct answer btn effect
  function handleClick(option, index) {
    setSelectorReturnValue(option);
    if (option === "correctAnswer") {
      setShowCorrectPopup(true);
      setSelectedOption(index);
    } else {
      setShowIncorrectPopup(true);
    }
  }

  useEffect(() => {
    setSelectedOption(null);
    setShowCorrectPopup(false);
    setShowIncorrectPopup(false);
  }, [question, options]);

  return (
    <div>
      <div className="higher-question-rectangle">
        <div className="question-text">{question}</div>
      </div>
      <div className="left-right-container">
        {options !== undefined ? (
          options.map((option, index) => (
            <button
              className={`left-right-btn ${
                selectedOption === index ? "correct-btn" : ""
              } ${
                selectedOption !== null && selectedOption !== index
                  ? "inactive-btn"
                  : ""
              }`}
              disabled={selectedOption !== null && selectedOption !== index}
              onClick={() => {
                handleClick(option.name, index);
              }}
            >
              {option.text}
            </button>
          ))
        ) : (
          <></>
        )}
      </div>
      {showCorrectPopup && (
        <CorrectPopUp message={message} setShowPopup={setShowCorrectPopup} />
      )}
      {showIncorrectPopup && (
        <IncorrectPopUp setShowPopup={setShowIncorrectPopup} />
      )}
    </div>
  );
}

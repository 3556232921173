import React from 'react';
import { Box } from "@mui/material";

const test = {
    width: 498,
    height: 127,
    top: 334,
    left: 658, 
    text: "Erupt + ion"
}

const RedTextBox = ({
    width, 
    height, 
    top, 
    left, 
    text, 
    fontSize
}) => {
    return (
            <Box sx={{
                border: ".5vw solid black",
                width: (width / 1280) * 100 + "vw", 
                height: (height / 1280) * 100 + "vw", 
                top: (top / 1280) * 100 + "vw", 
                left: (left / 1280) * 100 + "vw", 
                position: "absolute",


                backgroundColor: "#a7e4e1",
                border: ".5vw solid black",
                borderRadius: "1.5vw",
                display: "flex",

                color: "red",
                fontFamily: "Fredoka, sans-serif",
                fontWeight: 400,
                textAlign: "center",
                fontSize: (fontSize / 8.5) + "vh",
                fontWeight: "550"
            }}>
                <Box sx={{ margin: "auto" }}> {text} </Box>
            </Box>
    )
}

export default RedTextBox;
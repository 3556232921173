import { createContext } from "react"

// global context state is loaded when this page is loaded (once)
// add to this when we need a new kind of slide
export const globalContext = createContext({})
// export const globalContext = createContext({
//     currentSlideId: "665e7b048060991a53417a66",
//     name: "L1DummyPage",
//     displayHeader: true,
//     headerTopicText: "Topic 2: What Is Release?",
//     displayHeaderButtons: true,
//     specialHeader: false,
//     displayFooter: true,
//     displayFooterButtons: true,
//     footerNameText: "",
//     footerMainText: "Hello World",
//     footerHintText: "",
//     footerOnlyShowArrows: false,
// })

// state includes:
// header shown
// main header topic text
// special header (what is this for??)
// footer shown
// whether buttons shown on footer
// footer name text
// footer main text
// footer hint text
// list of elements currently on screen
import React, { useState, useEffect } from "react";
import { CorrectPopUp, IncorrectPopUp } from "../display/PopUp";
import "../../assets/styles/selection-styles.css";

export default function MCPictureInBtn({
  question,
  options,
  message,
  setSelectorReturnValue,
}) {
  const [selectedOption, setSelectedOption] = useState(null);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);

  // handle pop-up and correct answer btn effect
  function handleClick(option, index) {
    setSelectorReturnValue(option);
    if (option === "correctAnswer") {
      setShowCorrectPopup(true);
      setSelectedOption(index);
    } else {
      setShowIncorrectPopup(true);
    }
  }

  useEffect(() => {
    setSelectedOption(null);
    setShowCorrectPopup(false);
    setShowIncorrectPopup(false);
  }, [question, options]);

  return (
    <div>
      <div className="horizontal-mc-container">
        <div className="question-rectangle">
          <div className="question-text">{question}</div>
        </div>
        <div className="picture-btn-container">
          {/* setSelectorReturnValue determines if the answer is correct or incorrect */}
          {options !== undefined ? (
            options.map((option, index) => (
              <button
                className={`picture-btn ${
                  selectedOption === index ? "correct-btn" : ""
                } ${selectedOption !== null && selectedOption !== index ? "inactive-btn" : ""}`}
                disabled={selectedOption !== null && selectedOption !== index}
                onClick={() => {
                  handleClick(option.name, index);
                }}
                
              >
                <div className="picture-container">
                  <img
                    src={process.env.REACT_APP_BACKEND_HOST + option.media[0]}
                    alt=""
                  />
                </div>
                {option.text}
              </button>
            ))
          ) : (
            <></>
          )}
        </div>
      </div>
      {showCorrectPopup && (
        <CorrectPopUp message={message} setShowPopup={setShowCorrectPopup} />
      )}
      {showIncorrectPopup && (
        <IncorrectPopUp setShowPopup={setShowIncorrectPopup} />
      )}
    </div>
  );
}

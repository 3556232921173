import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PreviousArrowButton from "../components/backpacks/BackArrowButton";
import ContentListing from "../components/backpacks/ContentListing";
import TitleText from "../components/backpacks/TitleText";

const scrollableLessonsStyle = {
  height: "60vh",
  width: "80vw",
  backgroundColor: "white",
  border: ".7vw solid black",
  borderRadius: ".5vw",
  overflowY: "hidden", // hidden
  overflowX: "scroll",
  scrollbarColor: "#C2D2A3 transparent",
  whiteSpace: "nowrap",
  display: "flex",
  margin: "18vh auto",
};

const forTutoriaStyle = {
  height: "50vh",
  margin: "16vh auto",
};

export default function ModulePage({ showTutorial }) {
  const location = useLocation();
  const [moduleId, setModuleId] = useState(
    location.state === null ? "6668eb1300fe5e53fd010760" : location.state.moduleId === undefined ? "6668eb1300fe5e53fd010760" : location.state.moduleId
  );
  const [state, setState] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getModule/" + moduleId)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setState(data);
      });
  }, [moduleId]);

  return (
    <div>
      {!showTutorial && (
        <PreviousArrowButton // TODO not linked correctly
          previousPageName={"landingPage"}
        />
      )}

      <TitleText
        text={state.name} // TODO change name here
      />

      <div
        style={{
          ...scrollableLessonsStyle,
          ...(showTutorial && forTutoriaStyle),
        }}
      >
        {state.units !== undefined ? (
          state.units.map(
            (
              unit 
            ) => (
              <ContentListing
                completed={false} // Completed is whether the player has finished the lesson, its not implemented yet
                available={true} // TODO
                nameOfTopic={unit.name}
                lessonThumbnail={unit.thumbnail}
                scrollingCheck={false}
                modulesCheck={true}
                handleButtonClick={() => {
                  navigate("../unitPage", { state: { unitId: unit._id } });
                }}
              />
            )
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}

import React from 'react';
import { Box } from '@mui/material';

const greenTitleRectangleStyle = {
    //position: "fixed",
    display: "flex",
    margin: "auto",
    backgroundColor: '#C2D2A3',
    border: '.5vw solid black',
    borderRadius: '1vw',
    justifyContent: "center",

    color: 'black',
    fontFamily: "Fredoka, sans-serif",   
    fontWeight: 600,
    textAlign: 'center',
    fontSize: '3vw',
};

const GreenTitleBar = ({icon, title, boxWidth, topMargin}) => {
    if (topMargin == undefined) {
        topMargin = "12vh"
    }
    return (
        <Box style={{...greenTitleRectangleStyle, width: boxWidth, marginTop: topMargin }}>
            <img 
                src={icon} 
                style={{
                    height: "6vh",
                    objectFit: "contain", 
                    margin: "auto 1vw auto 0vw"}}>
            </img>
            <Box sx={{margin: "auto 1vw", display: "flex"}}>
                {title}
            </Box>
        </Box>
    )
}

export default GreenTitleBar;
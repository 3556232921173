export const highlightedImage = {
    "-webkit-filter": [
      "drop-shadow(3px 3px 0 #FFC702)",
      "drop-shadow(-3px -3px 0 #FFC702)",
      "drop-shadow(-3px 3px 0 #FFC702)",
      "drop-shadow(3px -3px 0 #FFC702)",
      "drop-shadow(3px 0px 0 #FFC702)",
      "drop-shadow(-3px 0px 0 #FFC702)",
      "drop-shadow(0px 3px 0 #FFC702)",
      "drop-shadow(0px -3px 0 #FFC702)",
    ],
    filter:
      "drop-shadow(3px 3px 0 #FFC702) \
          drop-shadow(-3px -3px 0 #FFC702) \
          drop-shadow(-3px 3px 0 #FFC702) \
          drop-shadow(3px -3px 0 #FFC702) \
          drop-shadow(3px 0px 0 #FFC702) \
          drop-shadow(-3px 0px 0 #FFC702) \
          drop-shadow(0px 3px 0 #FFC702) \
          drop-shadow(0px -3px 0 #FFC702)",
  };

export default highlightedImage
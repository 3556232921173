import React from "react";
import { Box } from "@mui/material";
import Slider from "@mui/joy/Slider";

import settingIcon from "../../assets/icons/UI_Settings_Icon.png";
import profileIcon from "../../assets/icons/profile-icon.png";
import studentBookIcon from "../../assets/icons/student-book-icon.png";
import schoolIcon from "../../assets/icons/school-icon.png";
import GreenTitleBar from "../../components/backpacks/GreenTitleBar";
import PreviousArrowButton from "../../components/backpacks/BackArrowButton";
import highlightedBox from "../../components/backpacks/HighlightBox";

const sliderTextStyle = {
  padding: "3vh 3%",
  border: ".5vw solid black",
  width: "60%",
  margin: "7vh auto",
  display: "flex",
  backgroundColor: "white",
  border: ".5vw solid black",
  borderRadius: "1.3vw",

  color: "black",
  fontFamily: "Fredoka, sans-serif",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "2.4vw",
};

const horzButtonsParent = {
  display: "flex",
  margin: "5vh 10%",
};

const viewInfoButtonStyle = {
  margin: "auto",
  width: "30vw",
  height: "8vh",
  backgroundColor: "white",
  border: ".5vw solid black",
  borderRadius: "1.3vw",
  display: "flex",

  color: "black",
  fontFamily: "Fredoka, sans-serif",
  fontWeight: 400,
  textAlign: "center",
  fontSize: "2.7vw",
};

const iconStyle = {
  height: "80%",
  margin: "1vh 3vw",
};

const InfoButtons = ({ icon, buttonName, highlighted }) => {
  return (
    <Box sx={{ ...viewInfoButtonStyle, ...(highlighted && highlightedBox) }}>
      <img src={icon} style={iconStyle}></img>
      <Box sx={{ margin: "auto 1vw" }}>{buttonName}</Box>
    </Box>
  );
};

export default function Setting({
  showTutorial,
  audioHighlighted,
  infoButtonHighlighted,
}) {
  return (
    <Box>
      {!showTutorial && (
        <PreviousArrowButton // TODO not linked correctly
        />
      )}
      <GreenTitleBar icon={settingIcon} title={"Settings"} boxWidth={"25%"} />

      <Box sx={{ ...sliderTextStyle, ...(audioHighlighted && highlightedBox) }}>
        Master Volume
        <Slider
          defaultValue={2}
          min={0}
          max={10}
          disableRipple
          valueLabelDisplay="off"
          variant="soft"
          sx={{
            "--Slider-trackSize": "2vh",
            "--Slider-thumbSize": "6vh",

            "& .MuiSlider-thumb": {
              background: "#A0E4F6",
              border: ".3vw solid black",
            },
            "& .MuiSlider-track": {
              background: "#C2D2A3",
              border: ".3vw solid black",
            },
            "& .MuiSlider-rail": {
              background: "#E5E5E5",
              border: ".3vw solid black",
            },
          }}
        />
      </Box>
      <Box sx={horzButtonsParent}>
        <InfoButtons
          icon={profileIcon}
          buttonName={"First Name"}
          highlighted={infoButtonHighlighted}
        />

        <InfoButtons
          icon={profileIcon}
          buttonName={"Last Name"}
          highlighted={infoButtonHighlighted}
        />
      </Box>

      <Box sx={horzButtonsParent}>
        <InfoButtons
          icon={schoolIcon}
          buttonName={"School Name"}
          highlighted={infoButtonHighlighted}
        />

        <InfoButtons
          icon={studentBookIcon}
          buttonName={"Student ID"}
          highlighted={infoButtonHighlighted}
        />
      </Box>
    </Box>
  );
}

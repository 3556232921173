import React, {useContext} from 'react';
import Switch, { switchClasses } from '@mui/joy/Switch';
import Typography from '@mui/joy/Typography';
import highlightedBox from '../../backpacks/HighlightBox';
import { globalContext } from '../../../Context';

const labelFontStyle = {
  color: "black", 
  fontFamily: "Fredoka, sans-serif",
  fontSize: '2.7vw',
  fontWeight: 400,
  margin: "auto", 
}

export default function EnEsButton({highlighted}) {
    const {isSpanish, setIsSpanish} = useContext(globalContext);
    return (
        <Switch
        variant={'outlined'}
        checked={isSpanish}
        onChange={(event) => setIsSpanish(event.target.checked)}
        slotProps={{
            track: {
              children: (
                <React.Fragment>
                  <Typography 
                    component="span" level="inherit" sx={{...labelFontStyle, opacity: "0.4"}}>
                    EN
                  </Typography>
                  <Typography component="span" level="inherit" sx={{...labelFontStyle, opacity: "0.4"}}>
                    ES
                  </Typography>
                </React.Fragment>
              ),
            },
            thumb: {
              children: 
              isSpanish? 
                <React.Fragment>
                <Typography component="span" level="inherit" sx={labelFontStyle}>
                  ES
                </Typography>
              </React.Fragment> : 
              
              <React.Fragment>
                <Typography component="span" level="inherit" sx={labelFontStyle}>
                  EN
                </Typography>
              </React.Fragment> 
            },
          }}
        sx={{
            "--Switch-trackWidth": "13vw",
            "--Switch-trackHeight": "8vh",
            "--Switch-trackRadius": ".5vw",
            '--Switch-trackBorderColor': '#000000',
            '--Switch-trackBackground': '#DFDEC1',

            "--Switch-thumbRadius": "3px",
            "--Switch-thumbWidth": "6vw",
            "--Switch-thumbSize": "7vh",
            '--Switch-thumbBackground': '#C2D2A3',
            '--Switch-thumbBorderColor': '#000000',

            '&:hover': {
              '--Switch-thumbBorderColor': '#000000',
              '--Switch-trackBorderColor': '#000000',
              '--Switch-trackBackground': '#DFDEC1',
              },
              
            [`&.${switchClasses.checked}`]: {
              '--Switch-thumbBackground': '#C2D2A3',
              '--Switch-trackBackground': '#DFDEC1',
              '--Switch-trackBorderColor': '#000000',

            '&:hover': {
              '--Switch-thumbBackground': '#C2D2A3',
              '--Switch-trackBackground': '#DFDEC1',
              '--Switch-trackBorderColor': '#000000',
            },
            },

            border: '.4vw solid black',
            borderRadius: '1vw',
            margin:'0.5vh', 
            ...(highlighted && highlightedBox)
        }}
        />
    );
  }
import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import lockIcon from "../../assets/icons/UI_Lock_Icon.png";
import { Box } from "@mui/material";

let parentStyle = {
  border: "1px solid #FFFFFF", 
  height: "100%", 
  display: "flex", 
  width: "100%"
}

let lessonStyle = {
  padding: "10%",
  textAlign: "center", // everything below is relating to text
  whiteSpace: "pre-wrap",
  color: "black",
  fontFamily: "Fredoka, sans-serif",
  fontSize: "4vh",
  fontWeight: 600,
  width: "20vw", 
};

let lessonAndLockStyle = {
  top:"5vh",
  height: "30vh",
  position: "relative"
}

let lessonIconStyle = {
  height: "23vh",
  objectFit: "contain",
  width: "100%",
};

let lockIconStyle = {
  height: "100%", 
  width: "100%", 
  position: "absolute", 
  top: "0", 
  margin: "auto"
}

let textPositionStyle = {
  top:"1vh", 
  position: "relative"
}
// TODO: maybe refactor this and TOCPageInstance item into a single thing?
const ContentListing = ({
  completed,
  available,
  nameOfTopic,
  lessonThumbnail,
  scrollingCheck,
  modulesCheck,
  handleButtonClick
}) => {
  const navigate = useNavigate();

  // const handleButtonClick = () => {
  //   console.log("moew");
  //   navigate("../lessonPage", { state: { lessonId: lessonId } }); // TODO fix with new data
  // };
  const handleButtonClickNone = () => {
    console.log("mdddoew");
  };

  return (
      <Box sx={parentStyle}> 
        <Box style={lessonStyle}>

        <Box sx={lessonAndLockStyle} onClick={available ? handleButtonClick : handleButtonClickNone}>
          <img
            style={{...lessonIconStyle, opacity: available ? "1" : ".3" }}
            src={process.env.REACT_APP_BACKEND_HOST + lessonThumbnail}/>

          <Box sx={lockIconStyle}>
            {!available && (
              <img style={{margin: "8vh auto"}} src={lockIcon}/>
            )}
          </Box>
        </Box>
          <Box sx={{...textPositionStyle, opacity: available ? "1" : ".3" }}> 
            {nameOfTopic}
          </Box>
        </Box>
        
      </Box>
  );
};

export default ContentListing;

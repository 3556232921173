import React from 'react';
import { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import RightButton from '../assets/buttons/Right_Button.png';
import UncheckedBox from '../static/White_Blank.png';
import CheckedBox from '../static/Checked_Box.png';
import AspectRatioCheck from "../components/AspectRatioCheck";

import GreenTitleBar from '../components/backpacks/GreenTitleBar';
import tocIcon from "../assets/icons/UI_TOC_Icon.png"
import BackArrowButton from '../components/backpacks/BackArrowButton';


const scrollableTopicListStyle = {
    margin: "8vh auto",
    height: "60vh",
    width: "80vw",
    backgroundColor: 'white',
    border: '.7vw solid black',
    borderRadius: '.5vw',
    overflowY: 'auto',
    overflowX: 'hidden', 
    scrollbarColor: '#C2D2A3 transparent', 
    direction: 'rtl'
};

const tutorialStyle = {
    height: '47vh',
    margin: "3vh auto",
}


const TopicListInstance = ({completed, available, nameOfTopic, topicNumber, slideId, lessonId}) => {
    const navigate = useNavigate();

    const handleButtonClick = () => {
        // TODO specify slideid
        navigate("../ContentPage", {state: {slideId: slideId, lessonId: lessonId}});
    }

    const handleButtonTitleClick = () => {
        navigate("../LessonPage", {state: {lessonId: lessonId}});
    }

    const handleButtonClickNone = () => {
    }
    
    const topicListInstanceStyle = {
        display: 'flex',
        justifyContent: "left",
        height: '10vh',
        margin: '2vh 4vw'  
    };

    const topicTextStyle = {
        fontFamily: "Fredoka, sans-serif",
        fontSize: '4vw',
        fontWeight: 550,
        marginTop:"auto",
        marginBottom:"auto",
        textWrap: "nowrap",
        margin: '0vh 2vw',
    };

    const CheckBox = ({checked}) => {
        const checkBoxStyle = {
            width: '5vw',
            height: 'auto',
            left: '1vw',
            top: '15%',
        };

        return (
            <div>
                <img
                    src={checked? CheckedBox : UncheckedBox}
                    alt="Checked Box"
                    style={checkBoxStyle}
                />
            </div>
        );
    };

    return (
        <div>
            <div style={{...topicListInstanceStyle, opacity: available ? '1' :'.3'}}>
                <img
                    src={RightButton}
                    alt="Right Button"
                    style={{ cursor: available ? 'pointer' : 'auto' }}
                    onClick={available ? topicNumber === 0 ? handleButtonTitleClick : handleButtonClick : handleButtonClickNone}
                />

                <div style={topicTextStyle}> 
                    {topicNumber}
                </div>

                <div style={{
                    width: "100%", 
                    background: "radial-gradient(circle at center 6vh, #999 8%, transparent 8%)",
                    backgroundSize: "2vh",
                    backgroundRepeat: "repeat-x",
                    }}>
                </div>

                <div style={topicTextStyle}>
                    {nameOfTopic}
                </div>

                <CheckBox checked={completed}/>

            </div>
        </div>
    );
};


export default function TOCPage({showTutorial}) {
    const location = useLocation();
    const [lessonId, setLessonId] = useState(location.state === null ? "666358b08060991a53417ac9" : location.state.lessonId === undefined ? "666358b08060991a53417ac9" : location.state.lessonId);
    const [state, setState] = useState({
    });
    const navigate = useNavigate();
    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_HOST + "api/getLesson/" + lessonId).then(
            response => response.json()
        ).then(
            data => {
                console.log("hi");
                console.log(data);
                setState(data);
            }
        )
    }, [lessonId]);

    let titleLength = ("Table of Content: " + state.name).length
    
    return (
        <div> 
            {!showTutorial &&
                <BackArrowButton showLeftButton={true} /> } 

            <GreenTitleBar
            icon={tocIcon}
            title={ ("Table of Content: " + state.name) } 
            boxWidth={`${titleLength + 3}ch`} />
                
            <div style={{...scrollableTopicListStyle, ...(showTutorial && tutorialStyle)}}>
                {
                    state.topics !== undefined ? state.topics.map((element, index) => (
                        <TopicListInstance completed={false} available={true} nameOfTopic={element.name} topicNumber={index} slideId={element.slideId} lessonId={lessonId}/>
                    )) : <></>
                }
            
            </div>
        </div>
    );
}
import React from "react";
import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import PreviousArrowButton from "../components/backpacks/BackArrowButton";
import ContentListing from "../components/backpacks/ContentListing";
import { Box } from "@mui/material";
import TitleText from "../components/backpacks/TitleText";

const scrollableLessonsStyle = {
  height: "60vh",
  width: "80vw",
  backgroundColor: "white",
  border: ".7vw solid black",
  borderRadius: ".5vw",
  overflowY: "hidden", // hidden
  overflowX: "scroll",
  scrollbarColor: "#C2D2A3 transparent",
  whiteSpace: "nowrap",
  display: "flex", 
  margin: "18vh auto"
};

const forTutoriaStyle = {
  height: "50vh",
  margin: "16vh auto"
}

export default function LandingPage({showTutorial}) {
  const location = useLocation();
  const [state, setState] = useState({});
  const navigate = useNavigate();
  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getModules/")
      .then((response) => response.json())
      .then((data) => {
        console.log(data);
        setState({data: data});
      });
  }, []);

  return (
    <Box sx={{ border: "1px solid red"}}>
      
      {!showTutorial && 
        <PreviousArrowButton showLeftButton={true} /> }
      
      <TitleText text={"Module Selection"} />

      <Box sx={{...scrollableLessonsStyle, ...(showTutorial && forTutoriaStyle)}}>
        {state.data !== undefined ? (
          state.data.map(
            (
              module // TODO place holder for the units
            ) => (
              
              <ContentListing
                completed={false} // Completed is whether the player has finished the lesson, its not implemented yet
                available={true} // TODO
                nameOfTopic={module.name}
                lessonThumbnail={module.thumbnail}
                scrollingCheck={false}
                modulesCheck={false}
                handleButtonClick={() => {
                  navigate("../modulePage", { state: { moduleId: module._id } });
                }}
              />
            )
          )
        ) : (
          <></>
        )}
      </Box>
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import { CorrectPopUp, IncorrectPopUp } from "../display/PopUp";
import "../../assets/styles/selection-styles.css";

export default function MCVerticalRight({
  options,
  message,
  setSelectorReturnValue,
}) {
  const [selectedOption, setSelectedOption] = useState(-1);
  const [showCorrectPopup, setShowCorrectPopup] = useState(false);
  const [showIncorrectPopup, setShowIncorrectPopup] = useState(false);

  function handleClick(option, index) {
    setSelectorReturnValue(option);
    if (option === "correctAnswer") {
      setShowCorrectPopup(true);
      setSelectedOption(index);
    } else {
      setShowIncorrectPopup(true);
    }
  }

  useEffect(() => {
    setSelectedOption(null);
    setShowCorrectPopup(false);
    setShowIncorrectPopup(false);
  }, [options]);

  // array of options for multiple choice
  // const options = answers[0].options;
  // const correctAnswer = answers[0].correctAnswer;
  return (
    <div className="container">
      <div className="mc-container">
        {/* setSelectorReturnValue determines if the answer is correct or incorrect */}
        {options !== undefined ? (
          options.map((option, index) => (
            <button
              className={`mc-btn ${
                selectedOption === index ? "correct-btn" : ""
              } ${
                selectedOption !== null && selectedOption !== index
                  ? "inactive-btn"
                  : ""
              }`}
              disabled={selectedOption !== null && selectedOption !== index}
              onClick={() => {
                handleClick(option.name, index);
              }}
            >
              {option.text}
            </button>
          ))
        ) : (
          <></>
        )}
      </div>
      {showCorrectPopup && (
        <CorrectPopUp message={message} setShowPopup={setShowCorrectPopup} />
      )}
      {showIncorrectPopup && (
        <IncorrectPopUp setShowPopup={setShowIncorrectPopup} />
      )}
    </div>
  );
}

import React, { useState, useContext, useEffect } from "react";
import "../../assets/styles/other-content-styles.css";
import "../../assets/styles/selection-styles.css";
import { globalContext } from "../../Context";
import MCLeftRight from "../activities/MCLeftRight";
import MCPictureInBtn from "../activities/MCPictureInBtn";
import MCButtons from "../activities/MCVerticalRight";
import VocabPostIt from "../display/VocabPostIt";
import { PictureInBtnExample, LeftRightExample, LeftRightDisplay } from "../display/MCExample";
import useSound from "use-sound";
import startRecord from "../../static/audio/Start-Recording.mp3"
import stopRecord from "../../static/audio/Stop-Recording.mp3"
import ImageSlider from "../ImageSlider";
import { useReactMediaRecorder } from "react-media-recorder-2";
import RedTextBox from "./RedTextBox";

const defaultStyleOutput = {
  position: "absolute",
  objectFit: "contain",
  top: "0vh",
  left: "0vw",
  width: "60vw",
  height: "60vh",
};

const redCircleStyle = {
  WebkitBorderRadius: "50px",
  MozBorderRadius: "50px",
  borderRadius: "50px",
  cursor: "pointer",
};

const redSquareStyle = {
  borderRadius: "1vh",
};

const Content = ({ content, setSelectorReturnValue }) => {
  const { slideId, setSlideId, state, setState, lessonId, setLessonId, isSpanish, setIsSpanish, audioSource, setAudioSource } =
  useContext(globalContext);

  // common to all content types
  let style = {
    ...defaultStyleOutput,
    //...(content.highlighted && highlighted),
    top: (content.top / 832) * 100 + "vh",
    left: (content.left / 1280) * 100 + "vw",
    width: (content.width / 1280) * 100 + "vw",
    height: (content.height / 832) * 100 + "vh",
  };

  // handle legacy schema
  // remove this if we ever need to use array media
  if (Array.isArray(content.media)) {
    content.media = content.media[0];
  }

  // for recording
  const {
    status,
    startRecording,
    stopRecording,
    pauseRecording,
    mediaBlobUrl,
  } = useReactMediaRecorder({
    video: false,
    audio: true,
    echoCancellation: true,
  });

  const [recordButtonState, setRecordButtonState] = useState(
    "Recording_Start.png"
  );
  const [multipleClickedObjects, setMultipleClickedObjects] = useState([]);

  const [recordAudioStart] = useSound(startRecord)
  const [recordAudioStop] = useSound(stopRecord)
  const handleRecordClick = () => {
    if (!state.hideNextButton) return;
    if (recordButtonState === "Recording_Start.png") {
      setRecordButtonState("Recording_Stop.png")
      recordAudioStart()
      startRecording()
    }
    else {
      setState({
        ...state,
        hideNextButton: false
      })
      setRecordButtonState("Recording_Start.png")
      recordAudioStop()
      stopRecording()
      // send media blob to backend
    }
  };

  useEffect(() => {
    if (content.audioSource) {
      console.log(content.audioSource)
      setAudioSource(content.audioSource)
    }
  }, [content])

  const [audio, {stop, duration}] = useSound(process.env.REACT_APP_BACKEND_HOST + content.audioSource, { volume: 0.25, loop: content.loop })
  useEffect(() => {
    if (content.type === "autoplayAudio") {
      audio()
    }
  }, [duration])

  useEffect(() => {
    stop()
  }, [slideId]);
  
  // handle the different items that are displayed on the content page
  // we have: sprite, selector (verticalRight, horizontal, leftRight), click-on, record, selection
  switch (content.type) {
    case "sprite":
      // sprite content type: use for static images, gifs, mp4s, etc
      // expected fields:
      // type: sprite
      // media is an array of strings - should really be one string but it's an array for legacy reasons
      // top, left, width, and height correspond to positioning of the media

      // todo: look into doing this with a template
      if (
        localStorage.getItem("volcanologistColor") !== null &&
        content.media.includes("{{volcanologistColor}}")
      ) {
        console.log(content.media);
        content.media = content.media.replace(
          "{{volcanologistColor}}",
          localStorage.getItem("volcanologistColor")
        );
      }

      return (
        <img
          src={process.env.REACT_APP_BACKEND_HOST + content.media}
          style={style}
          alt={`${content.media}`}
        />
      );

    case "selector":
      // selector content type: multiple clickables
      // each clickable will set some kind of state - handle it as such
      // expected fields:
      // type: selector
      // clickables: array of objs (either sprite or option)
      // format (optional): choose from predefined format for mcq
      // each sprite has top, left, width, and height, and media like sprite above
      // each option has text
      // both also has a field "name", which is what is passed to the state
      if (content.format !== undefined) {
        switch (content.format) {
          // the multiple choice where we have buttons as options on the right
          // hand side of sprites
          case "verticalRight":
            return (
              <MCButtons
                options={content.clickables}
                message={isSpanish ? content.messageEs : content.message}
                setSelectorReturnValue={setSelectorReturnValue}
              />
            );
          // the multiple choice where we have picture inside each button, the
          // picture is on top and the text is at the bottom inside the button
          case "pictureInBtn":
            return (
              <MCPictureInBtn
                question={content.question}
                options={content.clickables}
                message={isSpanish ? content.messageEs : content.message}
                setSelectorReturnValue={setSelectorReturnValue}
              />
            );
          // the multiple choice where the options are on the left and right of
          // the sprites
          case "leftRight":
            return (
              <MCLeftRight
                question={content.question}
                options={content.clickables}
                message={isSpanish ? content.messageEs : content.message}
                setSelectorReturnValue={setSelectorReturnValue}
              />
            );
          default:
            <></>;
        }
      } else {
        content.clickables.forEach(function (element) {
          element.style = {
            ...defaultStyleOutput,
            //...(content.highlighted && highlighted),
            top: (element.top / 832) * 100 + "vh",
            left: (element.left / 1280) * 100 + "vw",
            width: (element.width / 1280) * 100 + "vw",
            height: (element.height / 832) * 100 + "vh",
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
          };
          // special case for drag-drop
          element.endStyle = {
            ...defaultStyleOutput,
            //...(content.highlighted && highlighted),
            top: (element.newTop / 832) * 100 + "vh",
            left: (element.newLeft / 1280) * 100 + "vw",
            width: (element.width / 1280) * 100 + "vw",
            height: (element.height / 832) * 100 + "vh",
            backgroundColor: "transparent",
            outline: "none",
            border: "none",
          };
          element.onClick = () => {
            if (content.audioSource) {
              console.log("hi")
              console.log(process.env.REACT_APP_BACKEND_HOST + audioSource)
              document.getElementById("one_shot_audio").play()
            }
            console.log(content.audioSource)
            // special case for drag-drop
            if (element.name === "correctMultipleAnswer") {
              setMultipleClickedObjects([
                ...multipleClickedObjects,
                element.media,
              ]);
            }
            setSelectorReturnValue(element.name);
          };
        });

        return <>{content.clickables.map((element) => (
          <>
            {element.type === "sprite" ? (
              <img
                style={
                  element.name !== "correctMultipleAnswer" ||
                  !multipleClickedObjects.includes(element.media)
                    ? element.style
                    : element.endStyle
                }
                onClick={element.onClick}
                src={process.env.REACT_APP_BACKEND_HOST + element.media}
                alt={`${element.media}`}
              />
            ) : element.type === "option" ? (
              "unimplemented"
            ) : (
              <></>
            )}
          </>
        ))}
        </>;
      }
      break;

    // use when we need to click on an item and it moves on the next slide
    case "click-on":
      const handleClick = () => {
        setSelectorReturnValue("clickOn");
      };
      return (
        <img
          src={process.env.REACT_APP_BACKEND_HOST + content.media}
          style={style}
          alt={`${content.media}`}
          onClick={handleClick}
        />
      );

    // the blue rectangle with red text on the right side of sprite. only to display the word
    case "label":
      return (
        <div>
          <div className="label-rectangle">
            <div className="label-text">{content.text}</div>
          </div>
        </div>
      );

    // when introduce the new vocab in the post-it
    case "vocab":
      return <VocabPostIt text={content.text} />;


    // use when we need to record audio
    case "slider":
      console.log(content.changingImages);
      if (content.changingImages.media !== null) {
        content.changingImages[0].media.map((item) => {
          if (typeof item[0] === "string") {
            item[0] = item[0].replace(
              "{{volcanologistColor}}",
              localStorage.getItem("volcanologistColor")
            );
          }
        });
      }
      return (
        <ImageSlider
          changingImages={content.changingImages}
          sliderStyle={content.sliderStyle}
          audioSources={content.audioSources}
        />
      );
    case "record":
      return (
        <img
          src={`${process.env.REACT_APP_BACKEND_HOST}ui/${recordButtonState}`}
          style={style}
          onClick={handleRecordClick}
          alt=""
        />
      );

    case "selection":
      return <></>;

    case "example":
      if (content.format !== undefined) {
        switch (content.format) {
          case "pictureInBtn":
            return (
              <PictureInBtnExample
                question={content.question}
                options={content.options}
              />
            );
          case "leftRight":
            return (
              <LeftRightExample
                question={content.question}
                options={content.options}
              />
            );
            case "leftRightDisplay":
            return (
              <LeftRightDisplay
                options={content.options}
              />
            );
          default:
            // unknown - error reporting TODO
            return <></>;
        }
      }
      break;

    case "redTextBox": 
      return (
        <RedTextBox
          width={content.width}
          height={content.height}
          top={content.top}
          left={content.left}
          text={content.text}
          fontSize={content.fontSize}
        />
      )
    
    
    // case "autoplayAudio":
    //   audio()
    //   return (<></>)

    default:
      // unknown - error reporting TODO
      return <></>;
  }
};

export default Content;

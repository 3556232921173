import React from "react";
import "../../assets/styles/display-styles.css";

export default function VocabPostIt({ text }) {
  const topText = text[0];
  const bottomText1 = text[1];
  const bottomText2 = text[2];
  return (
    <div>
      <div className="post-it-container">
        <img
          src={`${process.env.REACT_APP_BACKEND_HOST}img/P_Postit_1.png`}
          alt="Post-it"
        />
        <button className="speaker-btn">
          <img
            src={`${process.env.REACT_APP_BACKEND_HOST}img/P_Speak_1.png`}
            alt="Speaker button"
          />
        </button>
        <p className="vocab-text top-text">{topText}</p>
        <p className="vocab-text bottom-text-1">{bottomText1}</p>
        <p className="vocab-text bottom-text-2">{bottomText2}</p>
      </div>
    </div>
  );
}

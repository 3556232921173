import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { globalContext } from "../Context.js";

import Content from "../components/contentPages/Content.js";
import MainFooter from "../components/contentPages/MainFooter.js";
import MainHeader from "../components/contentPages/MainHeader.js";

export default function ContentPage() {
  const location = useLocation();
  // console.log(location.state.slideId);
  const [slideId, setSlideId] = useState(location.state.slideId);
  const [lessonId, setLessonId] = useState(location.state.lessonId);
  const [state, setState] = useState({
    // defaults
    hideHeaderIcons: false,
    highlightLeftArrow: false,
    highlightRightArrow: false,
    hideNextButton: false,
    remainingSelections: 0,
  });
  useEffect(() => {
    fetch(process.env.REACT_APP_BACKEND_HOST + "api/getSlide/" + slideId)
      .then((response) => response.json())
      .then((data) => {
        // temp for friday
        if (data.footerText.includes("{{user.firstName}}")) {
          data.footerText = data.footerText.replace("{{user.firstName}}", "Jules")
        }
        setState(data);
        document.body.style.background = data.background;
        // console.log(data.background);
        // console.log(document.body.style.background);
        // console.log(data.hideHeaderIcons);
      });
  }, [slideId]);

  const [selectorReturnValue, setSelectorReturnValue] = useState("");
  const [audioSource, setAudioSource] = useState("")

  useEffect(() => {
    // huge switch case incoming...

    switch (selectorReturnValue) {
      case "":
        // default
        return;

      // volcanologist slide for choosing volcanologist
      case "blueVolcanologist":
      case "greenVolcanologist":
      case "pinkVolcanologist":
        localStorage.setItem(
          "volcanologistColor",
          selectorReturnValue.split("V")[0].charAt(0).toUpperCase() +
            selectorReturnValue.split("V")[0].slice(1)
        );
        setSlideId(state.nextSlide);
        setSelectorReturnValue("");
        return;

      // correct and incorrect mcq options
      case "correctAnswer":
        // setSlideId(state.nextSlide);
        setSelectorReturnValue("");
        return;
      case "incorrectAnswer":
        // TODO what do we do here?
        setSelectorReturnValue("");
        return;

      // multiple select
      case "correctMultipleAnswer":
        // do not attempt to use unless remainingSelections exists in state
        const remainingSelections = state.remainingSelections - 1
        setState({...state, remainingSelections: remainingSelections})
        if (remainingSelections <= 0) {
          setSlideId(state.nextSlide);
        }
        setSelectorReturnValue("");
        return;

      case "clickOn":
        setSlideId(state.nextSlide);
        setSelectorReturnValue("");
        return;
      default:
        // unimplemented
        setSelectorReturnValue("");
        return;
    }
  }, [selectorReturnValue]);

  const [isSpanish, setIsSpanish] = useState(false)

  return (
    <div>
      <globalContext.Provider
        value={{ slideId, setSlideId, state, setState, lessonId, setLessonId, isSpanish, setIsSpanish, audioSource, setAudioSource }}
      >
        <MainHeader
          render={state.headerText !== undefined}
          showButtons={!state.hideHeaderIcons}
          topicText={state.headerText}
        />

        {state.slideElements !== undefined ? (
          state.slideElements.map((element) => (
            <Content
              content={element}
              setSelectorReturnValue={setSelectorReturnValue}
            />
          ))
        ) : (
          <></> // empty content when both activity and slideElements are empty
        )}

        <audio src={process.env.REACT_APP_BACKEND_HOST + audioSource} id="one_shot_audio"></audio>

        <MainFooter
          render={state.footerText !== undefined}
          showLeftButton={state.prevSlide !== undefined}
          showRightButton={
            !(state.nextSlide === undefined || state.hideNextButton)
          }
          nameText={state.characterName}
          mainText={isSpanish ? state.footerTextEs : state.footerText}
          useSmallerText={isSpanish}
          returnUnit={state.returnUnit !== undefined ? state.returnUnit : null}
          // hintText={state.footerHintText}
        />
      </globalContext.Provider>
    </div>
  );
}

import React from "react";
import ContinueButton from "../../assets/buttons/Continue_Button.png";
import "../../assets/styles/display-styles.css";

export function CorrectPopUp({ message, setShowPopup }) {
  function handleClick() {
    setShowPopup(false);
  }
  return (
    <div className="popup-bg">
      <div className="popup-rectangle">
        <h3 className="popup-title">That is Correct!</h3>
        <p className="popup-text">{message}</p>
      </div>
      <img
        src={ContinueButton}
        className="continue-btn"
        alt="Continue button"
        onClick={handleClick}
      />
    </div>
  );
}

export function IncorrectPopUp({ setShowPopup }) {
    function handleClick() {
      setShowPopup(false);
    }
    return (
      <div className="popup-bg-2">
        <div className="popup-rectangle">
          <p className="popup-title-2">Try again!</p>
        </div>
        <img
          src={ContinueButton}
          className="continue-btn"
          alt="Continue button"
          onClick={handleClick}
        />
      </div>
    );
  }

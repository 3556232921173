import React from 'react';
import AspectRatioCheck from "../components/AspectRatioCheck";
import {useState, useEffect} from 'react';

import { useNavigate, useLocation } from 'react-router-dom';

const playButtonStyle = {
    position: 'absolute',
    // width: '20vw',
    paddingTop: '.5vh',
    paddingBottom: '.5vh',
    width: 'auto',
    height: 'auto',
    backgroundColor: 'white',
    left: AspectRatioCheck() ? '41%' : '38%',
    top: AspectRatioCheck() ? '70%' : '60%',
    border: '.5vw solid black',
    borderRadius: '1vw',
    color: 'black',
    fontFamily: "Fredoka, sans-serif",
    fontSize: AspectRatioCheck() ? '4vw' : '6vw',
    fontWeight: 500,
    paddingLeft: '1vw',
    paddingRight: '1vw',
    cursor: 'pointer',
};

const textStyle = {
    position: 'absolute',
    width: AspectRatioCheck() ? '40vw' : '50vw',
    height: 'auto',
    left: AspectRatioCheck() ? '30vw' : '25vw',
    top: AspectRatioCheck() ? '20vh' : '15vh',
}

export default function LessonPage() {
    const location = useLocation();
    const [lessonId, setLessonId] = useState(location.state === null ? "666358b08060991a53417ac9" : location.state.lessonId === undefined ? "666358b08060991a53417ac9" : location.state.lessonId);
    const [state, setState] = useState({
    });
    const navigate = useNavigate();
    const handleButtonClick = () => {
        console.log(state.firstSlide);
        navigate("../contentPage", {state: {slideId: state.firstSlide, lessonId: lessonId}});
    };
    useEffect(() => {
        fetch(process.env.REACT_APP_BACKEND_HOST + "api/getLesson/" + lessonId).then(
            response => response.json()
        ).then(
            data => {
                setState(data);
                
            }
        )
    }, [lessonId]);

    return (
        <div>
            <img 
                src={process.env.REACT_APP_BACKEND_HOST + state.titleImage}
                alt="IntroText"
                style={textStyle}
            />

            <div
                style={playButtonStyle}
                onClick={handleButtonClick}> 
                &nbsp;&nbsp;PLAY&nbsp;&nbsp;
            </div>

        </div>
    );
}